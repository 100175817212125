import React from "react";
import { Link } from "react-router-dom";
import Projects from "../../api/projects";

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const handlemail = () => {
    const mailto =
      "mailto:office@arisaevents.com?subject=Arisa Events - Informatii&body= ";
    window.location.href = mailto;
  };

  const handletel = () => {
    const telto = "tel:+40748958596";
    window.location.href = telto;
  };

  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <Link onClick={ClickHandler} className="logo" to="/">
                    <img src="../logo_with_letters_2.png" />
                  </Link>
                </div>
                <p>Make your dreams come true. With Arisa Events say I Do!</p>
                <div style={{ textAlign: "center" }}>
                  <Link onClick={ClickHandler} to="/home">
                    <img
                      src="../favicon_arisa.png"
                      alt="logo"
                      style={{ width: 100 }}
                    />
                  </Link>
                </div>
                {/* <ul>
                                <li>
                                    <Link onClick={ClickHandler} to="/">
                                        <i className="ti-facebook"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={ClickHandler} to="/">
                                        <i className="ti-twitter-alt"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={ClickHandler} to="/">
                                        <i className="ti-instagram"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={ClickHandler} to="/">
                                        <i className="ti-google"></i>
                                    </Link>
                                </li>
                            </ul> */}
              </div>
            </div>
            {/* <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Information</h3>
                            </div>
                            <ul>
                                <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                <li><Link onClick={ClickHandler} to="/portfolio">Portfolio</Link></li>
                                <li><Link onClick={ClickHandler} to="/blog">Latest News</Link></li>
                                <li><Link onClick={ClickHandler} to="/contact">Contact us</Link></li>
                                <li><Link onClick={ClickHandler} to="/service">Our Services</Link></li>
                            </ul>
                        </div>
                    </div> */}
            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3>Contact </h3>
                </div>
                <div className="contact-ft">
                  <p>
                    Would you have any enquiries. Please feel free to contuct us
                  </p>
                  <ul>
                    <li>Ariana Henzel</li>
                    <li onClick={handlemail} className="custom-email-icon">
                      <i className="fi flaticon-email"></i>
                      office@arisaevents.com
                    </li>
                    <li onClick={handletel} className="custom-tel-icon">
                      <i className="fi flaticon-phone-call"></i>+40 748 958 596
                    </li>
                    <li>
                      <i className="fi flaticon-maps-and-flags"></i>Sibiu,
                      Romania
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget instagram">
                            <div className="widget-title">
                                <h3>Instagram</h3>
                            </div>
                            <ul className="d-flex">
                                {Projects.slice(0,6).map((project, pitem) => (
                                    <li key={pitem}><Link onClick={ClickHandler} to={`/project-single/${project.id}`}><img src={project.pimg1} alt=""/></Link></li>
                                ))}
                            </ul>
                        </div>
                    </div> */}
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <p className="copyright">
                {" "}
                &copy; 2022 Arisa Events. Design By{" "}
                <Link onClick={ClickHandler} to="/">
                  Claudiu Popa
                </Link>
                . All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
